export default {
  navbar: {
    logo: {
      src: `/logo.svg`,
    },
    navbarLinks: {
      links: [`home`, `pricing`, `support`, `aboutUs`],
      home: {
        text: `home`,
        url: `/`,
      },
      pricing: {
        text: `pricing`,
        url: `/pricing`,
      },
      support: {
        text: `support`,
        url: `/support`,
      },
      aboutUs: {
        text: `about us`,
        url: `/about`,
      },
    },
    navbarButtons: {
      login: {
        text: `LOGIN`,
      },
      getStarted: {
        text: `GET STARTED`,
      },
    },
  },

  homepage: {
    sections: [
      `hero`,
      `section1`,
      `section2`,
      `testimonials`,
      `section3`,
      `statistics`,
      `section4`,
    ],

    hero: {
      video: {
        url: `/homepage/hero-background.mp4`,
      },
      title: {
        text: `Experience Yoga's\nTrue Essence`,
      },
      subTitle: {
        text: `Expert Guidance and Personalized Attention`,
      },
    },

    section1: {
      image: {
        url: `/homepage/woman-yoga-sitting-landscape.png`,
      },
      title: {
        text: `YOGA SHOULD BE STRESS FREE`,
      },
      list: [
        `Are you seeking an authentic yoga experience?`,
        `Looking for a personalized yoga journey within a thriving community?`,
        `Ready to start your yoga journey from anywhere with convenience?`,
      ],
      buttonText: `TRY STRESS FREE YOGA`,
    },

    section2: {
      images: [
        {
          url: `/homepage/woman-yoga-sitting-portrait.png`,
          text: `PRACTICE ANYWHERE`,
        },
        {
          url: `/homepage/woman-yoga-standing-portrait.png`,
          text: `AUTHENTICITY`,
        },
        {
          url: `/homepage/pregnant-woman-yoga-sitting-portrait.png`,
          text: `PERSONALIZED`,
        },
      ],
      title: {
        text: `AUTHENTIC, PERSONALIZED & PRACTICE ANYWHERE`,
      },
      content: [
        `**Authentic**: Cultivating deep self-awareness, fostering genuine connections and holistic growth`,
        `**Personalized**: Tailored guidance fostering individual growth, safety, and yoga development.`,
        `**Convenience**: Seamless practice, anywhere, with optimal comfort and privacy.`,
      ],
      buttonText: `GET STARTED`,
    },

    testimonials: {
      heading: `TESTIMONIALS`,
      title: `WHAT OUR CUSTOMERS SAY`,
      description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.`,
      listOfTestimonials: [
        {
          comment: `Yoga, especially Himalayan Hatha Yoga by @yogiashokananda, brings grounding, focus, and strength. His mastery in timing and sequencing enhances physical and energetic healing. Grateful for 12+ years of guidance, his precise delivery, and profound impact on emotional and energetic balance. #yogiashokananda #himalayanhathayoga #gratitude`,
          authorName: `Karita Massara`,
          authorDesignation: `Ortelle, Italy`,
          authorAvatar: `/homepage/comment-avatar-1.png`,
        },
        {
          comment: `Daily online classes with Yogi Ashokananda transformed my strength and healed recurring injuries. Each unique class blends Kria Yoga, Atma Kriya, Meditation, and Himalayan Hatha Yoga, fostering deep self-connection and health improvement. Grateful for accessible daily practice without traveling to India!`,
          authorName: `Dillaro Salim`,
          authorDesignation: `Ortelle, Italy`,
          authorAvatar: `/homepage/dillarosalim.png`,
        },
        {
          comment: `I am just joining @yogiashokananda Atma Meditation online to observe and finish my meditation and teacher training withhim.It’s amazing to watch 50+ people join from all over the world and watch them potter about at home before we start the meditation. Yogi has ALOT of free and online classes scheduled during lockdown. Make sure you book yourself one.`,
          authorName: `Zoe`,
          authorDesignation: `Ortelle, Italy`,
          authorAvatar: `/homepage/zoe.png`,
        },
        {
          comment: `Enrolled in Yogi's 'Intermediate' Himalayan Hatha Class tonight. Husband, feeling tricked, left, refusing photos of the challenging session. Sharing my chakrasana progress, yet, as @yogiashokananda emphasizes, yoga is invisible—it's in mental, physical, and emotional well-being. Yoga transcends asana; it's holistic.`,
          authorName: `Catherin`,
          authorDesignation: ``,
          authorAvatar: `/homepage/catherin.png`,
        },
        {
          comment: `Yoga, especially Himalayan Hatha Yoga by @yogiashokananda, brings grounding, focus, and strength. His mastery in timing and sequencing enhances physical and energetic healing. Grateful for 12+ years of guidance, his precise delivery, and profound impact on emotional and energetic balance. #yogiashokananda #himalayanhathayoga #gratitude`,
          authorName: `Karita Massara`,
          authorDesignation: `Ortelle, Italy`,
          authorAvatar: `/homepage/comment-avatar-1.png`,
        },
        {
          comment: `Daily online classes with Yogi Ashokananda transformed my strength and healed recurring injuries. Each unique class blends Kria Yoga, Atma Kriya, Meditation, and Himalayan Hatha Yoga, fostering deep self-connection and health improvement. Grateful for accessible daily practice without traveling to India!`,
          authorName: `Dillaro Salim`,
          authorDesignation: `Ortelle, Italy`,
          authorAvatar: `/homepage/dillarosalim.png`,
        },
        {
          comment: `I am just joining @yogiashokananda Atma Meditation online to observe and finish my meditation and teacher training withhim.It’s amazing to watch 50+ people join from all over the world and watch them potter about at home before we start the meditation. Yogi has ALOT of free and online classes scheduled during lockdown. Make sure you book yourself one.`,
          authorName: `Zoe`,
          authorDesignation: `Ortelle, Italy`,
          authorAvatar: `/homepage/zoe.png`,
        },
      ],
    },

    section3: {
      images: [
        {
          url: `/homepage/yogi-ashokananda-left.png`,
          text: `YOGI ASHOKANANDA`,
        },
        {
          url: `/homepage/katrina1.jpeg`,
          text: `KATRINA KAMARA`,
        },
        {
          url: `/homepage/yogi-ashokananda-main.png`,
          text: `YOGI ASHOKANANDA`,
        },
        {
          url: `/homepage/yogi-ashokananda-right.png`,
          text: `YOGI ASHOKANANDA`,
        },
      ],
      title: {
        text: `LEARN WITH THE BEST`,
      },
      paragraph: {
        text: `Embark on a transformative journey with our esteemed yoga masters and experienced instructors, all dedicated to living and imparting the essence of authentic yogic principles. Learn from the best as they guide you towards a deeper understanding of the ancient art of yoga and holistic well-being.`,
      },
      buttonText: `MEET THE MASTERS`,
    },

    statistics: {
      title: `Numbers Speak`,
      listOfStatistics: [
        {
          title: `54+`,
          subtitle: `Countries`,
          description: `Connecting practitioners across the globe for authentic yoga experiences.`,
        },
        {
          title: `48000+`,
          subtitle: `Participants`,
          description: `Thriving community embracing holistic wellness and personal transformation.`,
        },
        {
          title: `6000+`,
          subtitle: `Classes`,
          description: `Diverse offerings catering to all levels and wellness objectives.`,
        },
      ],
    },
  },
  footer: {
    title: `Experience Yoga's True Essence: Expert Guidance and Personalized Attention from Anywhere`,
    siteLinks: [
      { text: `Teach With Us`, url: `/` },
      { text: `Tatva at Work`, url: `/` },
      { text: `Contact Information`, url: `/` },
      { text: `Tatva at Home`, url: `/` },
      { text: `Address`, url: `/` },
      { text: `Tatva on the Go`, url: `/` },
    ],
    getAppContainer: {
      title: `Get the app`,
    },
  },
  supportpage: {
    FAQSection: {
      title: `Frequently asked **Questions**`,
      searchFilter: {
        placeholder: `Search Question`,
      },
      categoryList: [
        { text: `Class Joining`, value: `Class Joining` },
        { text: `Class Schedule`, value: `Class Schedule` },
        {
          text: `Support – Class, Schedule, Subscription & Teacher`,
          value: `Support – Class, Schedule, Subscription & Teacher`,
        },
        { text: `App Support`, value: `App Support` },
      ],
      queryList: [
        {
          title: `How do I book / schedule a class?`,
          content: `Via the mobile app, search through the classes by scrolling through “All Classes”\n\nOnce you find the class you want to book / schedule, click on the class.\n\nFrom here you click Schedule button or click on +Schedue beneath the image.\n\nYou will have the opportunity to select multiple days/times for the class.\n\nSelect a time, if you have a valid class pass, you will automatically be booked / scheduled for the class.\n\nIf you do not have a valid class pass, you will see a Purchase Button to buy a class pass.  Once you purchase your Class Pass you can return to Schedule the class.`,
          category: `Class Joining`,
        },
        {
          title: `Do I have to book / schedule every time I want to join a class?`,
          content: `If you have an unlimited pass you can book / schedule a class on a repeatable basis, click “repeat weekly” button.\n\nIf you have a limited class pass you can book/schedule a class on a repeatable basis, click “repeat weekly” button, up to the number of classes you have available.`,
          category: `Class Joining`,
        },
        {
          title: `How do I join the class from my mobile device?`,
          content: `You can join class from Mobile Device.\n\nEntering “join” on the Scheduled Class which is about to start.  Join on this device.`,
          category: `Class Joining`,
        },
        {
          title: `How do I join the class from my Laptop or Tablet?`,
          content: `You can join class from Laptop or Tables Device.\n\nEnter SCAN QR code to synchronise an external device.  On your preferred device, go to www.tatva.app  and scan the code to synchronize the mobile app with your selected device.\n\nEntering “join” on the Scheduled Class which is about to start.  Join on the linked device or go directly to www.tatva.app on the synchronized device and join the class directly from the device.`,
          category: `Class Joining`,
        },
        {
          title: `If I need to cancel a class, how do I do this?`,
          content: `You can cancel a class if not able to join, up until the scheduled start time.\n\nVia the mobile app, go to the class you want to cancel, click the Reschedule Button and then click on the time of the class you want to cancel.  Be aware this may also cancel your future repeatable class if you have any scheduled.`,
          category: `Class Joining`,
        },
        {
          title: `How can I find the classes with my favorite teacher(s)?`,
          content: `Mobile App:\n\nHome Screen, scroll to the bottom where you will find Teachers Section.\n\nClick on the teacher to advance to the screen with the description of the teacher and list of the classes taught.\n\nFind button will take you to the schedule for the selected class.`,
          category: `Class Schedule`,
        },
        {
          title: `What time zone is the class schedule in?`,
          content: `Class Schedule is in the same time zone as your mobile phone’s time zone.`,
          category: `Class Schedule`,
        },
        {
          title: `What is MY Schedule?`,
          content: `Mobile App & Website (linked device) My Schedule will display the classes you have Scheduled.`,
          category: `Class Schedule`,
        },
        {
          title: `How can I request more classes or classes at a specific time?`,
          content: `Mobile App Home Screen go to HELP at bottom of screen.\n\nOnce in Help you can send a query via “any other queries” to the Tatva team with your request.  This will take you to email to send.`,
          category: `Class Schedule`,
        },
        {
          title: `How can I pause my subscription?`,
          content: `Mobile App:\n\nYou can Pause your subscription 3 times in a 12-month period.\n\nWhen you want to Pause your subscription,\n\nGo to Profile on Home Screen\n\nEnter My Subscriptions \n\nSelect the 3 dots in top right hand corner on the subscription you want to pause\n\nA pop up choice will appear to Pause Subscription or Cancel Subscription\n\nSelect Pause Subscription.\n\nA prompt to ensure you want to Pause, will give you an option to Continue – YES or cancel the function – Go Back.\n\nResponding Yes, will take you to a calendar, where you can enter the start & end dates of the Pause.  Buttons at top of Calendar will allow you to cancel for 1 week, 1 month or 6 months.`,
          category: `Subscriptions`,
        },
        {
          title: `How can I cancel my subscription?`,
          content: `Mobile App: \n\nYou can Cancel your subscription at any time.\n\nGo to Profile on Home Screen\n\nEnter My Subscriptions\n\nSelect the 3 dots in top right hand corner on the subscription you want to pause\n\nA pop up choice will appear to Pause Subscription or Cancel Subscription\n\nSelect Cancel Subscription.\n\nA prompt to ensure you want to Cancel, will give you an option to Continue – OK or cancel the function – Cancel.\n\nResponding OK, will cancel your subscription at the next renewal date.\n\nYou will have the possibility to participate in classes until that time.`,
          category: `Subscriptions`,
        },
        {
          title: `How can I change my subscription?`,
          content: `Mobile App:\n\nYou can Upgrade your subscription at any time.\n\nGo to Profile on Home Screen
          Enter My Subscriptions
          Select the new Subscription you want and proceed through payment process.\n\nYou can upgrade from Bronze to Silver, Gold or Platinum\n\nYou can upgrade from Silver to Gold or Platinum.\n\nYou can upgrade from Gold to Platinum\n\nIf you want to change your subscription from:\n\nPlatinum to Bronze, Silver or Gold 
          Or from 
          Gold to Silver or Bronze 
          Or from 
          Silver to Bronze\n\nYou should cancel subscription and then purchase a new subscription.
          `,
          category: `Subscriptions`,
        },
        {
          title: `I have question about my subscription, where to get support?`,
          content: `Mobile App Home Screen go to HELP at bottom of screen.\n\nOnce in Help you can send a query via “any other queries” to the Tatva team with your request.  This will take you to email to send.\n\nAlternatively, you can start a chat session via WhatsApp.`,
          category: `Subscriptions`,
        },
        {
          title: `I have question about my invoice/payment, where to get support?`,
          content: `Mobile App Home Screen go to HELP at bottom of screen.\n\nOnce in Help you can send a query via “any other queries” to the Tatva team with your request.  This will take you to email to send.\n\nAlternatively, you can start a chat session via WhatsApp.`,
          category: `Subscriptions`,
        },
        {
          title: `How can I contact the teacher to ask a specific question about today’s class?`,
          content: `contact us at info@tatva.app and we will connect you to the teacher. Also we are working on a special feature where you can contact to the teacher directly.`,
          category: `Support – Class, Schedule, Subscription & Teacher`,
        },
        // {
        //   title: `How do I turn on/off notifications?`,
        //   content: ``,
        //   category: `App Support`,
        // },
        {
          title: `How do I report an issue using the platform?`,
          content: `Mobile App Home Screen go to HELP at bottom of screen.\n\nOnce in Help you can send a query via “any other queries” to the Tatva team with your request.  This will take you to email to send.\n\nAlternatively, you can start a chat session via WhatsApp.`,
          category: `App Support`,
        },
      ],
    },
    getInTouchSection: {
      title: `Get in Touch`,
      subtitle: `Have Questions or Ready to Begin? Reach Out to Us - We're Here to Help`,
      form: {
        name: {
          label: `Name`,
        },
        phone: {
          label: `Phone Number`,
        },
        dropdown: {
          adornment: `You are a`,
          options: [
            { text: `teacher`, value: `teacher` },
            { text: `student`, value: `student` },
          ],
        },
        message: {
          label: `Message`,
        },
        button: {
          text: `SEND`,
        },
      },
      contacts: {
        phone: {
          label: `PHONE`,
          value: `+447900343135`,
        },
        email: {
          label: `EMAIL`,
          value: `info@tatva.app`,
        },
      },
    },
    slaSection: {
      title: `**Support Options**`,
      description: `Thank you for using our application. We are committed to providing excellent support and ensuring you have the best possible experience. Below are the support options available to you:`,
      subtitle1: `First Response SLA:`,
      subDescription1: `Our goal is to respond to all support inquiries within 48 hours during our operating hours of **8:00 AM to 8:00 PM UK/London Time (BST)**. Customers can expect an initial response to their inquiry within this time frame, providing the necessary guidance and assistance to address their concerns.`,
      subtitle2: `Contact Us:`,
      subDescription2: `If you need direct assistance, you can contact our support team through the following channels:`,
      point1: `**Email Support:** Send an email to **teacher-support@tatva.app** with your questions or concerns.`,
      point2: `**Phone Support:** Call us at **+447900343135** during our hours of operation: Monday through Friday from **8 AM to 8 PM UK/London (BST)**.`,
      subtitle3: `Customer Support:`,
      subDescription3: `For real-time support, you can connect with a support agent through WhatsApp chat:`,
      point3: `**WhatsApp Chat:** Connect with a support agent at the number **+447900343135**.`,
      footer: `Thank you for choosing our application. We are here to assist you with any questions or concerns.`
    }
  },
  aboutpage: {
    sections: [`section1`, `section2`, `section3`],
    section1: {
      image: {
        url_desktop: `/aboutpage/about-page-yoga.png`,
        url_mobile: `/aboutpage/about-page-mobile.png`,
      },
      title: {
        text: `Our Vision`,
      },
      content: {
        desc1: `Our Purpose is to help Tatva participants to unlock the power of time-tested, holistic practices to redefine how we live - from mere doing to pure being.`,
        desc2: `Our purpose is to be more than just a yoga platform; we aspire to become a trailblazing Yoga and Technology company. Leveraging the fusion of ancient wisdom and cutting-edge innovations, we will lead the way in revolutionizing the yoga experience.`,
      },
    },
    section2: {
      title: {
        text: `The Benefits of Yoga with Tatva`,
      },
      list: [
        {
          listTitle: `Authenticity`,
          listDesc: `Authenticity is a vital aspect of Tatva’s offering. While practicing yoga live online, it is essential to prioritize authenticity in your practice. Our teachers embody the core principles and values of yoga, fostering a genuine and holistic approach.`,
        },
        {
          listTitle: `Personalized`,
          listDesc: `We prioritize personalized attention in our live online yoga classes. Our teachers take the time to understand your unique needs, limitations, and goals, offering modifications, adjustments, and individualized guidance to ensure a safe and effective practice.`,
        },
        {
          listTitle: `Practice Anywhere`,
          listDesc: `Tatva allows you to practice from the comfort of your own home or any other location with internet access. You do not have to commute to a studio or adhere to specific class schedules. This flexibility enables you to fit yoga into your routine more easily.`,
        },
      ],
    },
    section3: {
      title: {
        text: `Meet the Team`,
      },
      list: [
        {
          image: {
            url: `/aboutpage/default-profile.png`,
          },
          name: `Anurag Jain`,
          desc: `Lorem Ipsum`,
        },
        {
          image: {
            url: `/aboutpage/default-profile.png`,
          },
          name: `Yogi Ashokananda`,
          desc: `Lorem Ipsum`,
        },
        {
          image: {
            url: `/aboutpage/default-profile.png`,
          },
          name: `Michele Carvalho`,
          desc: `Lorem Ipsum`,
        },
      ],
    },
    popUp: {
      Authenticity: {
        title: `Authenticity`,
        background: `/aboutpage/pregnant-woman-exercise.png`,
        mobile_background: `/aboutpage/mobile-exercise.png`,
        desc: [
          {
            title: `Mindful Teaching`,
            content: `Tatva’s teachers understand that yoga is a deeply personal practice and encourage students to honor their unique bodies, abilities, and limitations. You will receive modifications and options, empowering you to adapt the practice to your needs while maintaining safety and integrity.`,
          },
          {
            title: `Respect for Individuality`,
            content: `Tatva’s teachers understand that yoga is a deeply personal practice and encourage students to honor their unique bodies, abilities, and limitations. You will receive modifications and options, empowering you to adapt the practice to your needs while maintaining safety and integrity.`,
          },
          {
            title: `Alignment with Tradition`,
            content: `Tatva’s teachers understand that yoga is a deeply personal practice and encourage students to honor their unique bodies, abilities, and limitations. You will receive modifications and options, empowering you to adapt the practice to your needs while maintaining safety and integrity.`,
          },
        ],
      },
      Personalized: {
        title: `Personalized`,
        background: `/aboutpage/pregnant-woman-yoga.png`,
        mobile_background: `/aboutpage/mobile-exercise.png`,
        desc: [
          {
            title: `Variety of Classes`,
            content: `Tatva provides a wide range of yoga classes, catering to different styles, levels, durations, and intentions. You can explore different teachers and styles to find what resonates with you the most. This variety helps keep your practice fresh and prevents monotony.`,
          },
          {
            title: `Flexibility and Progression`,
            content: `Tatva allows you to practice at your own pace and choose the classes that suit your needs. You can also track your progress over time, access past classes, and revisit specific practices.`,
          },
          {
            title: `Access to Expertise`,
            content: `Tatva features experienced and knowledgeable teachers from around the world. You can access their expertise and guidance regardless of your geographical location. It is an opportunity to learn from renowned teachers who might not be available locally.`,
          },
        ],
      },
      "Practice Anywhere": {
        title: `Practice Anywhere`,
        background: `/aboutpage/pregnant-woman-yoga.png`,
        mobile_background: `/aboutpage/mobile-exercise.png`,
        desc: [
          {
            title: `Privacy and Comfort`,
            content: `Practicing at your preferred location gives you the freedom to create a space that feels comfortable and safe. You can wear what you prefer, adjust the temperature, and practice without feeling self-conscious. This privacy can deepen your focus and relaxation during yoga sessions.`,
          },
          {
            title: `Convenience`,
            content: `Tatva allows you to practice from the comfort of your own home or any other location with internet access. You do not have to commute to a studio or adhere to specific class schedules. This flexibility enables you to fit yoga into your routine more easily.`,
          },
          {
            title: `Cost-Effectiveness`,
            content: `Live Online yoga classes are more affordable than in-person studio sessions. We offer subscription-based options or individual class purchases that fit within your budget. This accessibility makes yoga more financially sustainable for many people.`,
          },
        ],
      },
    },
  },
  pricepage: {
    sections: [`section1`, `section2`, `section3`],
    section1: {
      title: {
        text: `Choose Your Subscription `,
      },
    },
    section2: {
      title: [`Features`, `Blue`, `Bronze`, `Silver`, `Gold`, `Platinum`],
      features: [
        `Access to community classes`,
        `Add on class price`,
        // `Gift your classes to new member`,
        `Get frequents gifts`,
        `Available Classes`,
        `Yogshala Videos`,
      ],
      list: [
        {
          "Access to community classes": [
            `&#x2713;`,
            `&#x2713;`,
            `&#x2713;`,
            `&#x2713;`,
            `&#x2713;`,
          ],
        },
        { "Add on class price": [`£ 12`, `£ 10`, `£ 8`, `£ 6`, `£ 6`] },
        // {
        //   "Gift your classes to new member": [
        //     `-`,
        //     `-`,
        //     `&#x2713;`,
        //     `&#x2713;`,
        //     `&#x2713;`,
        //   ],
        // },
        { "Get frequents gifts": [`-`, `-`, `-`, `&#x2713;`, `&#x2713;`] },
        {
          "Available Classes": [
            `Community <h5>Classes</h5>`,
            `Community <h5>Classes</h5>`,
            `4<h5>any discipline</h5>`,
            `Unlimited<h5>one discipline</h5>`,
            `Unlimited<h5>any discipline</h5>`,
          ],
        },
        {
          "Yogshala Videos": [
            `Free Videos Only`,
            `&#x2713;`,
            `&#x2713;`,
            `&#x2713;`,
            `&#x2713;`,
          ],
        },
      ],
      details: {
        Features: {
          width_desktop: `21.88063rem`,
          width_mobile: `5rem`,
          background: `white`,
          color: `black`,
        },
        Blue:  {
          width_desktop: `16.35069rem`,
          width_mobile: `4.3rem`,
          background: `blueCardBackground`,
          color: `white`,
        },
        Bronze: {
          width_desktop: `16.35069rem`,
          width_mobile: `4.3rem`,
          background: `bronzeCardBackground`,
          color: `white`,
        },
        Silver: {
          width_desktop: `16.35069rem`,
          width_mobile: `4.3rem`,
          background: `silverCardBackground`,
          color: `white`,
        },
        Gold: {
          width_desktop: `16.35069rem`,
          width_mobile: `4.3rem`,
          background: `goldCardBackground`,
          color: `white`,
        },
        Platinum: {
          width_desktop: `16.35069rem`,
          width_mobile: `4.3rem`,
          background: `platinumCardBackground`,
          color: `black`,
        },
      },
    },
    section3: {
      list: [
        {
          planPrice: 0,
          priceText: ``,
          recommend: false,
          plan: {
            title: `BLUE`,
            topLeft: { title: ``, value: `` },
            bottomLeft: { title: `Subscribed on`, value: `` },
            bottomRight: { title: `COMMUNITY`, value: `CLASS` },
            background: `blueCardBackground`,
            color: `white`,
          },
          description: [
            `Free access to community classes`,
            `Access to drop in classes for £12`,
            `Access to free Yogshala Videos`,
            `For membership register with your credit card `,
          ],
          buttonText: `SUBSCRIBE`,
        },
        {
          planPrice: 6,
          priceText: `/ 1 Month`,
          recommend: false,
          plan: {
            title: `BRONZE`,
            topLeft: { title: `Subscribed on`, value: `` },
            bottomLeft: { title: `Next renewal`, value: `` },
            bottomRight: { title: ``, value: `` },
            background: `bronzeCardBackground`,
            color: `white`,
          },
          description: [
            `Free access to community classes`,
            `Free access to All Yogshala Videos`,
            `Access to drop in classes for £10`,
            `Upgrade any time`,
          ],
          buttonText: `SUBSCRIBE`,
        },
        {
          planPrice: 24,
          priceText: `/ 4 Classes`,
          recommend: false,
          plan: {
            title: `SILVER`,
            topLeft: { title: `Subscribed on`, value: `` },
            bottomLeft: { title: `Next renewal`, value: `` },
            bottomRight: { title: ``, value: `` },
            background: `silverCardBackground`,
            color: `white`,
          },
          description: [
            `Monthly 4 classes`,
            `Add-on class for £ 8`,
            `Free access to All Yogshala Videos`,
            `Upgrade any time`,
          ],
          buttonText: `SUBSCRIBE`,
        },
        {
          planPrice: 50,
          priceText: `/ UNLIMITED`,
          recommend: true,
          plan: {
            title: `GOLD`,
            topLeft: { title: `Subscribed on`, value: `` },
            bottomLeft: { title: `Next renewal`, value: `` },
            bottomRight: { title: `ANY ONE`, value: `DISCIPLINE` },
            background: `goldCardBackground`,
            color: `white`,
          },
          description: [
            `Unlimited classes from any one discipline`,
            `Add-on class for £ 6`,
            `Free access to All Yogshala Videos`,
            `Upgrade any time`,
          ],
          buttonText: `SUBSCRIBE`,
        },
        {
          planPrice: 120,
          priceText: `/ unlimited`,
          recommend: false,
          plan: {
            title: `PLATINUM`,
            topLeft: { title: `Subscribed on`, value: `` },
            bottomLeft: { title: `Next renewal`, value: `` },
            bottomRight: { title: `UNLIMITED`, value: `CLASSES` },
            background: `platinumCardBackground`,
            color: `black`,
          },
          description: [
            `Unlimited classes from any discipline`,
            `Free access to All Yogshala Videos`,
            `Get frequent user “GIFTS”`,
          ],
          buttonText: `SUBSCRIBE`,
        },
        // {
        //   planPrice: 10,
        //   priceText: `/ 1 Classes`,
        //   recommend: false,
        //   plan: {
        //     title: `CLASS PASS`,
        //     topLeft: { title: ``, value: `` },
        //     bottomLeft: { title: `Vallid till`, value: `` },
        //     bottomRight: { title: `01`, value: `CLASS` },
        //     background: `bronzeCardBackground`,
        //     color: `white`,
        //   },
        //   description: [
        //     `Free access to community classes`,
        //     `Can be used for any class.`,
        //     `Access to drop in classes for £10`,
        //     `Upgrade any time`,
        //   ],
        //   buttonText: `SUBSCRIBE`,
        // },
      ],
    },
    recommend: `RECOMMENDED`,
  },
  privacypage: {
    "logo": {
      "src": "/logo.svg"
    },
    "sections": ["section1", "section2", "section3", "section4", "section5", "section6", "section7","section8","section9","section10"],
    "section1": {
      "title": {
        "text": "Privacy Policy"
      },
      "content":["Tatva Wellness Ltd. built the Tatva app as a Commercial app. This SERVICE is provided by Tatva Wellness Ltd. and is intended for use as it is.","This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.","If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.","        The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which are accessible at Tatva unless otherwise defined in this Privacy Policy."]
    },
    "section2": {
      "title": {
        "text": "Information Collection and Use"
      },
      "content":["For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information. The information that we request will be retained by us and used as described in this privacy policy.", "The app does use third-party services that may collect information used to identify you.", "<ul>Link to the privacy policy of third-party service providers used by the app","<li><a href='https://www.google.com/policies/privacy/' target='_blank' rel='noopener noreferrer'> Google Play Services </a></li><li><a href='https://firebase.google.com/support/privacy/' target='_blank' rel='noopener noreferrer'>Firebase Crashlytics</a></li></ul>"]
    },
    "section3": {
      "title": {
        "text": "Log Data"
      },
      "content":[" We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third-party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics."]
    },
    "section4": {
      "title": {
        "text": "Cookies"
      },
      "content":["Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device internal memory.", "This Service does not use these “cookies” explicitly. However, the app may use third-party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service."]
    },
    "section5": {
      "title": {
        "text": "Service Providers"
      },
      "content":["<ul>We may employ third-party companies and individuals due to the following reasons:","<li>To facilitate our Service;</li><li>To provide the Service on our behalf;</li><li>To perform Service-related services; or</li><li>To assist us in analyzing how our Service is used.</li></ul>","We want to inform users of this Service that these third parties have access to their Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose."]
    },
    "section6": {
      "title": {
        "text": "Security"
      },
      "content":["We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security."] 
    },
    "section7": {
      "title": {
        "text": "Links to Other Sites"
      },
      "content":["This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services."] 
    },
    "section8": {
      "title": {
        "text": "Children Privacy"
      },
      "content":["These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13 years of age. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do the necessary actions."]
     },
     "section9": {
       "title": {
         "text": "Changes to This Privacy Policy"
       },
       "content":["We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.","This policy is effective as of 2023-06-21"]
      },
      "section10": {
        "title": {
          "text": "Contact Us"
        },
        "content":["If you have any questions or suggestions about our Privacy Policy, do not hesitate to contsact us at info@tatva.app."]
       }
  }
};
